<template>
  <div class="shopping-cart">
    <CartItem v-for="item in items" :key="item" :item="item" />
  </div>
</template>

<script>
import CartItem from './CartItem';

export default {
  name: "ShoppingCart",
  props: ["items"],
  components: {
    CartItem
  }
}
</script>

<style lang="scss" scoped>
  .shopping-cart {
    width: 100%;
    max-width: 600px;
  }
</style>