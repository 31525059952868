<template>
  <div class="variation-picker">
    <div class="flex-div" v-if="variations.length <= 3">
      <div class="variation noselect" v-bind:class="{ selected: variation == v }" v-for="v in variations" :key="v" @click="selectVariation(v)">{{ v }}</div>
    </div>

    <BigSelect class="variation-select" v-else :items="selectFormattedVariations" v-model="variation" @change="selectVariation(variation)" />
  </div>
</template>

<script>
export default {
  name: "VariationPicker",
  props: {
    variations: {
      required: true
    }
  },
  data() {
    return {
      variation: undefined
    }
  },
  watch: {
    variations(variations) {
      this.selectVariation(variations[0]);
    }
  },
  computed: {
    selectFormattedVariations() {
      var fomattedVariations = [];

      this.variations.forEach(variation => {
        fomattedVariations.push({ value: variation, text: variation});
      });

      return fomattedVariations;
    }
  },
  methods: {
    selectVariation(variation) {
      this.variation = variation;
      this.$emit('pickedVariation', variation);
    }
  },
  mounted() {
    this.selectVariation(this.$props.variations[0]);
  }
}
</script>

<style lang="scss" scoped>
  .variation-picker {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .flex-div {
    display: flex;
    flex-wrap: wrap;
  }

  .variation {
    background-color: white;
    border: 2px solid black;
    color: black;
    margin: 10px;
    padding: 7px 10px 5px 10px;
    font-family: 'Sahar-Bold', Arial;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      background-color: #FCFCFC;
    }

    &.selected {
      background-color: black;
      color: white;
    }
  }

  .variation-select {
    width: calc(100% - 20px) !important;
    margin: 15px 10px !important;
  }
</style>