import Vuex from 'vuex';
import Vue from 'vue';
import cart from './modules/cart';
import website from './modules/website';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cart,
        website
    }
});