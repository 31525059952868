<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter" @afterLeave="$root.$emit('triggerScroll')">
        <router-view :key="$route.fullPath"/>
      </transition>
    </component>
  </div>
</template>

<script>
import { shopMixins } from '@/mixins/shop';
import { websiteMixins } from '@/mixins/website';

const default_layout = "default";

export default {
  name: 'App',
  mixins: [shopMixins, websiteMixins],
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
  methods: {
    /* TRANSITION ANIMATION METHODS */
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    }
  },
  created() {
    this.retrieveCartFromCookies();
    this.getWebsiteVars();
  }
}
</script>

<style>
  html, body, #app {
    height: 100%;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Sahar-Regular', Arial;
  }

  #app {
    font-family: "BentonSans-Regular", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
    letter-spacing: 0.5px;
    background-color: #fff;
  }

  h1, h2, h3 {
    font-family: 'Sahar-Bold', Arial;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }
  
  a, a:visited,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  li, ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .center {
    text-align: center;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

  /* ROUTER TRANSITION ANIMATION */
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: .3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  /*    FONTS     */
  @font-face {
    font-family: 'Sahar-Thin';
    src: url('./assets/fonts/Sahar/Sahar-Thin.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Sahar-Regular';
    src: url('./assets/fonts/Sahar/Sahar-Regular.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Sahar-Bold';
    src: url('./assets/fonts/Sahar/Sahar-Bold.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Sahar-Heavy';
    src: url('./assets/fonts/Sahar/Sahar-Heavy.ttf');
    font-display: swap;
  }
</style>
