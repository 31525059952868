<template>
  <div id="page-container">
    <div class="darkOverlay" v-if="showDarkOverlay && isMobile" @click="hideOverlay"></div>

    <Header v-if="isDesktop" />
    <MobileHeader v-else-if="isMobile" ref='mobileHeader' />

    <div id="content-wrap">
      <slot/>
    </div>

    <Footer />

    <Cookiebar v-bind:class="{ active: !acceptedCookies }" />
 </div>
</template>

<script>
import Header from '@/components/layout/Header';
import MobileHeader from '@/components/layout/MobileHeader';
import Footer from '@/components/layout/Footer';
import Cookiebar from '@/components/layout/Cookiebar';

export default {
  data () {
    return {
      windowWidth: window.innerWidth,
      showDarkOverlay: false,
      acceptedCookies: true
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768 ? true : false;
    },
    isDesktop() {
      return this.windowWidth >= 768 ? true : false;
    }
  },
  components: {
    Header,
    MobileHeader,
    Footer,
    Cookiebar
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    showOverlay: function() {
      this.showDarkOverlay = true;
    },
    hideOverlay: function() {
      this.$refs.mobileHeader.showMoreView = false;
      this.$refs.mobileHeader.showBurgerView = false;
      this.showDarkOverlay = false;
    },
    acceptCookies() {
      this.acceptedCookies = true;
    }
  },
  created() {
    this.acceptedCookies = this.$cookie.get("acceptsCookies") == "true";
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss">
  #page-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .darkOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    background-color: black;
    opacity: 0.3;
  }

  .mobileHeader { }
  
  #content-wrap {
    padding-top: 60px;
    padding-bottom: 30rem;    /* Footer height */
    margin: auto;
    display: flex;
    flex: 1;
    width: 100%;

    @include tablet {
      padding-top: 80px;
      padding-bottom: 17.5rem;    /* Footer height */
    }
  }

  #content-wrap > * {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 20px;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25rem;    /* Footer height */

    @include tablet {
      height: 15rem;            /* Footer height */
    }
  }
</style>