<template>
  <div class="cart">
    <h1>{{ items.length > 0 ? `SHOPPING CART (${cart_items_count})` : "YOUR SHOPPING BAG IS EMPTY" }}</h1>

    <div class="flexbox" v-if="items.length > 0">
      <ShoppingCart :items="items" />
      <TotalsAndDelivery :items="items" />
    </div>
  </div>
</template>

<script>
import { shopMixins } from '@/mixins/shop';

import ShoppingCart from '@/components/cart/ShoppingCart';
import TotalsAndDelivery from '@/components/cart/TotalsAndDelivery';

export default {
  name: 'Cart',
  mixins: [shopMixins],
  computed: {
    items() {
      return this.cart_items;
    }
  },
  components: {
    ShoppingCart,
    TotalsAndDelivery
  }
}
</script>

<style lang="scss" scoped>
  .cart {

  }

  h1 {
    text-align: center;
    margin: 30px auto;
  }

  .flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .shopping-cart {
      width: 100%;

      @include desktop {
        width: 60%;
      }
    }

    .totals-and-delivery {
      width: 100%;

      @include desktop {
        width: 35%;
      }
    }
  }
</style>