import Vue from 'vue'
import firebase from 'firebase'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCSCk_myqsfREZWsv74mnXYUzEoRU3qzC4', // gemaakt bij https://console.cloud.google.com/apis/credentials
    //libraries: 'places' ( of keuze uit places,drawing,visualization, ...)
  },
  installComponents: true
})

import BigButton from "./components/global/BigButton.vue"
Vue.component("BigButton", BigButton)
import BigSelect from "./components/global/BigSelect.vue"
Vue.component("BigSelect", BigSelect)

import Default from "./layouts/Default.vue"
Vue.component("default-layout", Default)

Vue.config.productionTip = false

let app = ''

firebase.initializeApp({
  apiKey: "AIzaSyCa8EA65Cjcsk30jBOcPfKoM20dsQTKL-4",
  authDomain: "webshop8-3bc62.firebaseapp.com",
  databaseURL: "https://webshop8-3bc62.firebaseio.com",
  projectId: "webshop8-3bc62",
  storageBucket: "webshop8-3bc62.appspot.com",
  messagingSenderId: "677960812026",
  appId: "1:677960812026:web:1963d293d1c2f4c9711043"
})

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})