<template>
  <div class="sale-conditions" v-html="main_text">
    
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: "SaleConditions",
  data() {
    return {
      main_text: ''
    }
  },
  methods: {
    loadContent() {
      firebase.firestore().collection("content").doc("sale_conditions").get().then((doc) => {
        var content = doc.data();
        this.main_text = content.main_text;
      })
    }
  },
  created() {
    this.loadContent();
  }
}
</script>

<style lang="scss">
  .sale-conditions {
    h2 {
      margin: 20px 0;
    }

    ul {
      margin: 20px 0;
      
      li {
        list-style-type: disc;
        margin-left: 20px;
      }
    }
  }
</style>