const state = { 
    cart: []
};

const getters = { 
    getCart: state => state.cart
};

const actions = {
    setCart({commit}, cart) {
        commit("setCart", cart);
    },
    clearCart({commit}) {
        commit("clearCart");
    },
    addItem({commit}, item) {
        commit("addItem", item);
    },
    removeItem({commit}, id) {
        commit("removeItem", id);
    },
    decreaseItem({commit}, params) {
        commit("decreaseItem", params);
    },
    updateQuantity({commit}, params) {
        commit("updateQuantity", params);
    },
};

const mutations = {
    setCart(state, cart) {
        state.cart = cart;
    },
    clearCart(state) {
        state.cart = [];
    },
    addItem(state, item) {
        item = {...item};

        // already in cart
        var found = false;
        state.cart.forEach((i, index) => {
            if (i.id == item.id) {
                if ((item.variation && item.variation != i.variation) || (item.size && item.size != i.size)) {
                    // variatie of size komen niet overeen, terwijl dat wel zou moeten.
                } else {
                    found = true;
                    state.cart[index].quantity += 1;
                }
            }
        });
        
        // not in cart yet
        if (!found){
            item.quantity = item.quantity ? item.quantity : 1;
            state.cart.push(item);
        }
    },
    removeItem(state, item) {
        state.cart.forEach((i, index) => {
            if (i.id == item.id) {
                if ((item.variation && item.variation != i.variation) || (item.size && item.size != i.size)) {
                    // variatie of size komen niet overeen, terwijl dat wel zou moeten.
                } else {
                    state.cart.splice(index, 1);
                }
            }
        });
    },
    decreaseItem(state, item) {
        state.cart.forEach((i, index) => {
            if (i.id == item.id) {
                if ((item.variation && item.variation != i.variation) || (item.size && item.size != i.size)) {
                    // variatie of size komen niet overeen, terwijl dat wel zou moeten.
                } else {
                    if (item.quantity) {
                        state.cart[index].quantity -= 1;
    
                        if (state.cart[index].quantity <= 0) {
                            state.cart.splice(index, 1);
                        }
                    } else {
                        state.cart.splice(index, 1);
                    }
                }
            }
        });
    },
    updateQuantity(state, item) {
        if (item.id && item.quantity) {
            state.cart.forEach((i, index) => {
                if (i.id == item.id) {
                    if ((item.variation && item.variation != i.variation) || (item.size && item.size != i.size)) {
                        // variatie of size komen niet overeen, terwijl dat wel zou moeten.
                    } else {
                        i.quantity = item.quantity;
        
                        if (i.quantity <= 0) {
                            state.cart.splice(index, 1);
                        }
                    }
                }
            });
        }
    },
    /*
    removeItem(state, id) {
        state.cart.forEach((item, index) => {
            if (item.id == id) {
                state.cart.splice(index, 1);
            }
        });
    },
    decreaseItem(state, params) { // params -> id (verplicht) & te verwijderen quantity nodig (optioneel , anders - 1)
        state.cart.forEach((item, index) => {
            if (item.id == params.id) {
                if (params.quantity) {
                    state.cart[index].quantity -= params.quantity || 1;

                    if (item.quantity <= 0) {
                        state.cart.splice(index, 1);
                    }
                } else {
                    state.cart.splice(index, 1);
                }
            }
        });
    },
    updateQuantity(state, params) { // params -> id & te wijzigen quantity (verplicht)
        if (params.id && params.quantity) {
            state.cart.forEach((item, index) => {
                if (item.id == params.id) {
                    item.quantity = params.quantity;
    
                    if (item.quantity <= 0) {
                        state.cart.splice(index, 1);
                    }
                }
            });
        }
    }
    */
};

export default {
    state,
    getters,
    actions,
    mutations
}