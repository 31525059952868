<template>
  <div class="contact-block">
    <h2>Contact</h2>
    
    <div class="form">
        <div class="form-row">
            <input type="text" placeholder="Name" v-model="name">
            <input type="email" placeholder="E-mail" v-model="email">
        </div>

        <input type="email" placeholder="Confirm E-mail" v-model="fakeEmail" style="display: none;">

        <textarea placeholder="Message..." v-model="message" />

        <BigButton @click.native="send">SEND</BigButton>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: "ContactBlock",
  data() {
    return {
      name: "",
      email: "",
      message: "",

      fakeEmail: ""
    }
  },
  methods: {
    send() {
      if (this.name.trim().length == 0) {
        alert("Please fill in your name.");
      }
      else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email.trim()))) {
        alert("Please fill in a valid e-mail address.");
      }
      else if (this.message.trim().length == 0) {
        alert("Please leave a message.");
      }
      else {
        if (this.fakeEmail.length == 0) {
          firebase.firestore().collection("contact_messages").add({
            name: this.name.trim(),
            email: this.email.trim(),
            message: this.message.trim()
          }).then(() => {
            this.name = "";
            this.email = "";
            this.message = "";

            alert("Message sent succesfully!");
          }).catch(() => {
            alert("Oops, something went wrong!");
          });
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .contact-block {
    padding: 100px 0;
    width: 100%;
  }

  h2 {
    text-align: center;
    font-size: 30px;
  }

  .form {
    width: 80%;
    max-width: 700px;
    margin: auto;

    .form-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 30px 0;

        @include tablet {
            flex-direction: row;
        }

        input {
            width: 100%;

            @include tablet {
                width: 48%;
            }

            &:first-child {
                margin-bottom: 30px;

                @include tablet {
                    margin-bottom: 0;
                }
            }
        }
    }
  }

  input {
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Sahar-Regular';
    font-size: 20px;
    padding: 3px 10px;

    -webkit-appearance: none;
    border-radius: 0;
  }

  textarea {
    border: 2px solid black;
    width: 100%;
    height: 200px;
    font-family: 'Sahar-Regular';
    font-size: 20px;
    padding: 10px;
    margin-bottom: 30px;

    -webkit-appearance: none;
    border-radius: 0;
  }

  .big-button {
    width: 40%;
    margin: auto;
  }
</style>