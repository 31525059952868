<template>
  <div class="information">
    <h2>Your information</h2>

    <div class="form">
        <div class="form-row">
            <input type="text" placeholder="First Name" v-model="form.firstname">
            <input type="text" placeholder="Last Name" v-model="form.lastname">
        </div>
        <input type="email" placeholder="E-mail" v-model="form.email">
    </div>

    <div class="address-form form" v-if="showAddressForm">
        <p>Please let us know where we need to deliver your order.</p>

        <BigSelect :items="countries" v-model="form.country" />

        <div class="form-row">
            <input type="text" placeholder="Street" v-model="form.street">
            <input type="text" placeholder="Number" v-model="form.number">
        </div>

        <div class="form-row">
            <input type="text" placeholder="ZIP" v-model="form.zip">
            <input type="text" placeholder="City" v-model="form.city">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Information",
  props: ["showAddressForm"],
  data() {
    return {
      countries: [{ value: "BE", text: "Belgium" }], //  { value: "NL", text: "Netherlands" }
      form: {
        firstname: '',
        lastname: '',
        email: '',
        country: 'BE',
        street: '',
        number: '',
        zip: '',
        city: '',
      }
    }
  },
  methods: {
    validate() {
        if (this.form.firstname.trim().length == 0) {
            alert("Please fill in your first name.");
            return false;
        }
        else if (this.form.lastname.trim().length == 0) {
            alert("Please fill in your last name.");
            return false;
        }
        else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email))) {
            alert("Please fill in a correct e-mail address.");
            return false;
        }
        else if (this.$props.showAddressForm) {
            if (this.form.street.trim().length == 0) {
                alert("Please fill in a valid street name.");
                return false;
            }
            else if (this.form.number.trim().length == 0) {
                alert("Please fill in a valid house number.");
                return false;
            }
            else if (this.form.zip.trim().length == 0) {
                alert("Please fill in a valid zip.");
                return false;
            }
            else if (this.form.city.trim().length == 0) {
                alert("Please fill in a valid city.");
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  .information {
    margin: 40px 0;
  }

  .form {
    width: 100%;
    max-width: 700px;

    .form-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 30px 0;

        @include tablet {
            flex-direction: row;
        }

        input {
            width: 100%;

            @include tablet {
                width: 48%;
            }

            &:first-child {
                margin-bottom: 30px;

                @include tablet {
                    margin-bottom: 0;
                }
            }
        }
    }
  }

  input {
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Sahar-Regular';
    font-size: 20px;
    padding: 3px 10px;
    width: 100%;

    -webkit-appearance: none;
    border-radius: 0;
  }

  .address-form {
    margin: 40px 0;

    .big-select {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
    }
  }
</style>