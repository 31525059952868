<template>
  <div class="cart-item">
    <div class="left-side">
      <div class="image-container noselect" v-bind:class="{ failed: imageFailed }" @click="goToItem">
        <img :src="imageSource" @error="picLoadError" />
      </div>
    </div>

    <div class="info">
      <strong class="product-name" @click="goToItem">{{ item.name }}</strong>
      <span v-if="item.variation">{{ item.variation }}</span>
      <span v-if="item.size">Size: {{ item.size }}</span>

      <div class="quantity-editor">
        Quantity:
        <img src="@/assets/images/feather/minus.svg" @click="decreaseQuantity">
        <span class="quantity">{{ item.quantity }}</span>
        <img src="@/assets/images/feather/plus.svg" @click="increaseQuantity">
      </div>
      <span v-if="stock == 0" class="error">Out of stock.</span>
      <span v-else-if="stock > 0 && stock <= 3" class="warning">Only {{ stock == 1 ? '1 item' : stock + " items" }} left.</span>

      <span v-if="item.pickupOnly" style="margin: 5px 0; color: orange;">Pick-up Only</span>

      <p class="bottom-bar">
        <a @click="remove">Remove</a>
        <strong class="price">{{ formatPrice(item.price * item.quantity) }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
import { shopMixins } from '@/mixins/shop';

export default {
  name: "CartItem",
  mixins: [shopMixins],
  props: ["item"],
  data() {
    return {
      imageFailed: false,
      imageSource: ''
    }
  },
  computed: {
    stock() {
      var item;

      if (this.$props.item.variations.length > 0 && this.$props.item.sizes.length > 0) {
        item = this.$props.item.stock.find(s => s.variant === `${this.$props.item.size}-${this.$props.item.variation}`)
      } else if (this.$props.item.variations.length > 0) {
        item = this.$props.item.stock.find(s => s.variant === `${this.$props.item.variation}`)
      } else if (this.$props.item.sizes.length > 0) {
        item = this.$props.item.stock.find(s => s.variant === `${this.$props.item.size}`)
      } else {
        item = this.$props.item.stock.find(s => s.variant === `DEFAULT`)
      }

      if (item.quantity == 'Unlimited') {
        return 999999;
      }
      
      return item ? item.quantity : -1;
    }
  },
  methods: {
    goToItem() {
      this.$router.push({ path: '/item/' + this.$props.item.id });
    },
    decreaseQuantity() {
      this.decreaseItemFromCart(this.$props.item);
    },
    increaseQuantity() {
      if (this.stock - this.$props.item.quantity > 0) {
        this.addItemToCart(this.$props.item);
      } 
    },
    remove() {
      this.removeItemFromCart(this.$props.item);
    },
    formatPrice(price) {
      return isNaN(price) ? "" : "€ " + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', ', ');
    },
    picLoadError () {
      this.imageFailed = true;
      this.imageSource = require('@/assets/images/feather/image.svg');
    }
  },
  created() {
    this.imageFailed = false;
    this.imageSource = `https://firebasestorage.googleapis.com/v0/b/webshop8-3bc62.appspot.com/o/products%2F${this.$props.item.id}.jpg?alt=media`
  }
}
</script>

<style lang="scss" scoped>
  .cart-item {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }

  .left-side {
    width: 30%;

    .image-container {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      padding: 100% 0 0 0;
      margin: auto;
      cursor: pointer;

      &.failed {
        background-color: #ECECEC;
        
        img {
          width: 30%;
          margin: auto;
          transition-duration: 0s;
        }
      }

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition-duration: .3s;
        margin: auto;
      }
    }
  }

  .info {
    width: 65%;
    display: flex;
    flex-direction: column;
    font-size: 12px;

    @include tablet {
      font-size: 14px;
    }

    @include desktop {
      font-size: 15px;
    }
    
    .product-name {
      margin-bottom: 20px;
      display: block;
      font-family: 'Sahar-Bold';
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .quantity-editor {
      display: flex;
      align-items: center;

      .quantity {
        line-height: 40px;
        padding-top: 5px;
        font-family: 'Sahar-Bold';
      }

      img {
        width: 20px;
        height: 20px;
        padding: 10px;
        box-sizing: content-box;
        cursor: pointer;
        transition-duration: .3s;

        &:hover {
          opacity: .4;
        }
      }
    }

    .error {
      color: rgb(228, 0, 0);
    }

    .warning {
      color: rgb(197, 128, 0);
    }
    
    .bottom-bar {
      display: flex;
      margin-top: auto;

      .price {
        margin-left: auto;
      }
    }

    a {
      cursor: pointer;
      color: rgb(69, 69, 69);
      text-decoration: underline;
      transition-duration: .3s;

      &:hover {
        opacity: .4;
      }
    }
  }
</style>