<template>
  <div class="about">
    <h1>About Us</h1>
    <p v-html="main_text" class="content"></p>
    

    <img src="../assets/images/kapsalon-foto.jpg" class="kapsalon-foto">

    <h2 style="margin-top: 20px;">Find us</h2>
    <p style="margin-bottom: 20px;">
      Van Iseghemlaan 128<br>
      8400 Oostende<br>
      België
    </p>

    <GmapMap :center="{ lat: 51.2284464, lng: 2.910866 }" :zoom="15" map-type-id="roadmap" class="map">
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        :clickable="true"
        @click="center=m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: "About",
  data() {
    return {
      main_text: '',
      markers: [{ lat: 51.2284464, lng: 2.910866 }]
    }
  },
  methods: {
    getPageContent() {
      firebase.firestore().collection("content").doc("about").get().then((doc) => {
        var content = doc.data();

        this.main_text = content.main_text;
      })
    }
  },
  created() {
    this.getPageContent();
  }
}
</script>

<style lang="scss">
  .about {

    .map {
      width: 100%;
      height: 300px;

      @include tablet {
        width: 580px;
        height: 350px;
      }

      @include desktop {
        width: 660px;
        height: 400px;
      }
    }

    .content {
      margin: 20px 0 40px 0;
      max-width: 800px;

      ul {
        li {
          list-style-type: disc;
          margin-left: 20px;
        }
      }
    }

    .kapsalon-foto {
      width: 100%;
      max-width: 800px;
    }
  }
</style>