<template>
  <div class="size-picker">
    <div class="flex-div" v-if="sizes.length <= 3">
      <div class="size noselect" v-bind:class="{ selected: size == s }" v-for="s in sortedSizes" :key="s" @click="selectSize(s)">{{ s }}</div>
    </div>
    
    <BigSelect class="size-select" v-else :items="selectFormattedSortedSizes" v-model="size" @change="selectSize(size)" />
  </div>
</template>

<script>
export default {
  name: "SizePicker",
  props: {
    sizes: {
      required: true
    }
  },
  data() {
    return {
      size: undefined
    }
  },
  watch: {
    sizes(sizes) {
      this.selectSize(sizes[0]);
    }
  },
  computed: {
    sortedSizes() {
      var sizes = [...this.sizes];
      var sortedSizes = [];
      var order = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];

      order.forEach(size => {
        if (this.sizes.indexOf(size) != -1) {
          sortedSizes.push(size);
          sizes.splice(sizes.indexOf(size), 1);
        }
      });

      sortedSizes = sortedSizes.concat(sizes);

      return sortedSizes;
    },
    selectFormattedSortedSizes() {
      var fomattedSizes = [];

      this.sortedSizes.forEach(size => {
        fomattedSizes.push({ value: size, text: size});
      });

      return fomattedSizes;
    }
  },
  methods: {
    selectSize(size) {
      this.size = size;
      this.$emit('pickedSize', size);
    }
  },
  mounted() {
    this.selectSize(this.$props.sizes[0]);
  }
}
</script>

<style lang="scss" scoped>
  .size-picker {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .flex-div {
    display: flex;
    flex-wrap: wrap;
  }

  .size {
    background-color: white;
    border: 2px solid black;
    color: black;
    margin: 10px;
    padding: 7px 10px 5px 10px;
    font-family: 'Sahar-Bold', Arial;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      background-color: #FCFCFC;
    }

    &.selected {
      background-color: black;
      color: white;
    }
  }

  .size-select {
    width: calc(100% - 20px) !important;
    margin: 15px 10px !important;
  }
</style>