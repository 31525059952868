<template>
  <div class="item-list">
    <Item v-for="item in items" :key="item.id" :item="item" />
    <div class="empty-space" v-for="n in emptySpaces" :key="n" />
    <h1 v-if="items && items.length == 0">No products found.</h1>
  </div>
</template>

<script>
import Item from './Item';

export default {
  name: "ItemList",
  props: ["items"],
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    emptySpaces() {
      if (this.windowWidth >= 1200) {
        return this.items.length % 4 != 0 ? 4 - (this.items.length % 4) : 0;
      } else if (this.windowWidth >= 992) {
        return this.items.length % 3 != 0 ? 3 - (this.items.length % 3) : 0;
      } else if (this.windowWidth >= 767) {
        return this.items.length % 2 != 0 ? 2 - (this.items.length % 2) : 0;
      } else {
        return 0;
      }
    }
  },
  components: {
    Item
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
  .item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .item, .empty-space {
      width: 100%;

      @include tablet {
        width: calc(50% - 20px);
      }

      @include desktop {
        width: calc(33% - 20px);
      }

      @include big {
        width: calc(25% - 20px);
      }
    }
  }
</style>