<template>
  <div class="contact">
    <h1>CONTACT</h1>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style lang="scss" scoped>
  .contact {
    
  }
</style>