import firebase from 'firebase';
import { mapActions } from 'vuex';

export const websiteMixins = {
  methods: {
    ...mapActions(["setCategories", "setNavCategories", "disableShop"]),
    getWebsiteVars() {
        firebase.firestore().collection("global").doc("public_website_vars").get().then((doc) => {
            var vars = doc.data();

            this.setCategories(vars.categories);
            this.setNavCategories(vars.categories_in_nav);

            vars.disable_shop ? this.disableShop(vars.disable_shop_warning) : this.disableShop('');
        })
    }
  }
}