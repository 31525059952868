<template>
  <div class="detail-view">
    <h2 class="name">{{ product.name }}</h2>
    <p class="description">{{ product.description }}</p>
    <h3 class="price">{{ formatPrice(product.price) }}</h3>

    <VariationPicker :variations="product.variations" @pickedVariation="pickVariation" />
    <SizePicker :sizes="product.sizes" @pickedSize="pickSize" />

    <BigButton ref="btnAdd" @click.native="addToCart" v-bind:class="{ disabled: !productIsSelected }">ADD TO CART</BigButton>
    
    <span class="stock-alert" v-bind:class="{ error: stock == 0, warning: stock > 0 && stock < 4 }">
      {{ stock == 0 ? "Sold out, please try another combination." : `Warning, only ${stock == 1 ? '1 item' : stock + " items"} of this combination left!` }}
    </span>

  </div>
</template>

<script>
import VariationPicker from './VariationPicker';
import SizePicker from './SizePicker';

import { shopMixins } from '@/mixins/shop';

export default {
  name: "DetailView",
  props: ["product"],
  mixins: [shopMixins],
  data() {
    return {
      variation: undefined,
      size: undefined
    }
  },
  computed: {
    productIsSelected() {
      if (this.$props.product.variations.length > 0 && this.variation == undefined) {
        return false;
      } else if (this.$props.product.sizes.length > 0 && this.size == undefined) {
        return false;
      } else {
        // keuze is gemaakt (indien nodig), nu kijken of er nog genoeg stock is voor keuze
        return (this.stock > 0 && this.stock > this.getQuantityOfItemInCart(this.item_to_add));
      }
    },
    stock() {
      var item;

      if (this.$props.product.variations.length > 0 && this.$props.product.sizes.length > 0) {
        item = this.$props.product.stock.find(s => s.variant === `${this.size}-${this.variation}`)
      } else if (this.$props.product.variations.length > 0) {
        item = this.$props.product.stock.find(s => s.variant === `${this.variation}`)
      } else if (this.$props.product.sizes.length > 0) {
        item = this.$props.product.stock.find(s => s.variant === `${this.size}`)
      } else {
        item = this.$props.product.stock.find(s => s.variant === `DEFAULT`)
      }
      
      if (item && item.quantity == 'Unlimited') {
        return 999999;
      }
      
      return item ? item.quantity : -1;
    },
    item_to_add() {
      var item_to_add = {...this.$props.product};
      
      if (this.$props.product.variations.length > 0) {
        item_to_add.variation = this.variation || this.$props.product.variations[0];
      }
      if (this.$props.product.sizes.length > 0) {
        item_to_add.size = this.size || this.$props.product.sizes[0];
      }

      return item_to_add;
    }
  },
  methods: {
    addToCart() {
      this.$refs.btnAdd.load();

      this.addItemToCart(this.item_to_add);

      setTimeout(() => {
        this.$refs.btnAdd.complete("added");
      }, 300);
    },
    formatPrice(price) {
      return isNaN(price) ? "" : "€ " + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', ', ');
    },
    pickVariation(variation) {
      this.variation = variation;
    },
    pickSize(size) {
      this.size = size;
    }
  },
  components: {
    VariationPicker,
    SizePicker
  }
}
</script>

<style lang="scss" scoped>
  .detail-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10%;
  }

  .name, .description, .price {
    margin: 10px 0;
  }



  .stock-alert {
    margin: 10px 0;
    opacity: 0;

    &.error, &.warning {
      opacity: 1;
    }
  }

  .error {
    color: rgb(228, 0, 0);
  }

  .warning {
    color: rgb(197, 128, 0);
  }

  .variation-picker, .size-picker {
    margin-left: -10px;
  }
</style>