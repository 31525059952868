const state = { 
    categories: [],
    navCategories: [],
    disable_shop_warning: ''
};

const getters = { 
    getCategories: state => state.categories,
    getNavCategories: state => state.navCategories,
    getDisableShopWarning: state => state.disable_shop_warning
};

const actions = {
    setCategories({commit}, categories) {
        commit("setCategories", categories);
    },
    setNavCategories({commit}, navCategories) {
        commit("setNavCategories", navCategories);
    },
    disableShop({commit}, warning) {
        commit("disableShop", warning);
    }
};

const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
    },
    setNavCategories(state, navCategories) {
        state.navCategories = navCategories;
    },
    disableShop(state, warning) {
        state.disable_shop_warning = warning;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}