//import firebase from 'firebase';
import { mapGetters, mapActions } from 'vuex';

export const shopMixins = {
  computed: {
    cart_items() {
      return this.getCart();
    },
    cart_items_count() {
      var total = 0;

      this.cart_items.forEach(item => {
        total += item.quantity;
      });

      return total;
    }
  },
  methods: {
    ...mapGetters(["getCart"]),
    ...mapActions(["setCart", "clearCart", "addItem", "removeItem", "decreaseItem", "updateQuantity"]),
    retrieveCartFromCookies() {
      var cart = JSON.parse(this.$cookie.get('shopping_cart'));
      cart = cart ? cart : [];
      
      this.setCart(cart);
    },
    saveInCookies() {
      this.$cookie.set('shopping_cart', JSON.stringify(this.cart_items), { expires: '1Y' });
    },
    resetCart() {
      this.clearCart();
      this.saveInCookies();
    },
    addItemToCart(item) {
      item.quantity = item.quantity ? item.quantity : 1;
      this.addItem(item);
      this.saveInCookies();
    },
    removeItemFromCart(item) {
      this.removeItem(item);
      this.saveInCookies();
    },
    decreaseItemFromCart(item) {
      this.decreaseItem(item);
      this.saveInCookies();
    },

    getQuantityOfItemInCart(item) {
      var quantity = 0;

      this.cart_items.forEach(cart_item => {
        if (cart_item.id == item.id && cart_item.variation == item.variation && cart_item.size == item.size) {
          quantity = cart_item.quantity;
        }
      });

      return quantity;
    }
  }
}