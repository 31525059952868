<template>
  <div class="item-image image-container noselect" v-bind:class="{ failed: imageFailed }">
    <img :src="imageSource" @error="picLoadError" />
  </div>
</template>

<script>

export default {
  name: "ItemImage",
  props: ["itemId"],
  data() {
    return {
      imageFailed: false,
      imageSource: ''
    }
  },
  watch: {
    item() {
      this.imageFailed = false;
      this.imageSource = `https://firebasestorage.googleapis.com/v0/b/webshop8-3bc62.appspot.com/o/products%2F${this.$props.itemId}.jpg?alt=media`
    }
  },
  methods: {
    picLoadError () {
      this.imageFailed = true;
      this.imageSource = require('@/assets/images/feather/image.svg');
    }
  },
  created() {
    this.imageFailed = false;
    this.imageSource = `https://firebasestorage.googleapis.com/v0/b/webshop8-3bc62.appspot.com/o/products%2F${this.$props.itemId}.jpg?alt=media`
  }
}
</script>

<style lang="scss" scoped>
  .image-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0 0;

    &.failed {
      background-color: #ECECEC;
      
      img {
        width: 30%;
        margin: auto;
        transition-duration: 0s;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition-duration: .3s;
      margin: auto;
    }
  }
</style>