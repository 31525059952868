<template>
  <div class="checkout">
    <h1>Checkout</h1>

    <p v-if="items.length == 0">
      Nothing in cart.
    </p>
    
    <div v-else>
      <Delivery :hasPickupOnlyItems="hasPickupOnlyItems" :hasDeliveryItems="hasDeliveryItems" @deliveryTypeChanged="updateDeliveryType" />
      <Address ref="addressForm" :showAddressForm="hasDeliveryItems && deliveryType == 'deliver'" :deliveryType="deliveryType" />

      <BigButton ref="btnCheckout" @click.native="tryConfirm" :disabled="items.length == 0" v-bind:class="{ disabled: items.length == 0 }">Go to Payment</BigButton>
    </div>
  </div>
</template>

<script>
import { shopMixins } from '@/mixins/shop';
import { mapGetters } from 'vuex';

import Delivery from '@/components/checkout/Delivery.vue';
import Address from '@/components/checkout/Address';

export default {
  name: "Checkout",
  mixins: [shopMixins],
  data() {
    return {
      deliveryType: "deliver"
    }
  },
  watch: {
    shopDisabled(val) {
      if (val === true) {
        this.$router.push("/");
      }
    }
  },
  computed: {
    shopDisabled() {
      return this.getDisableShopWarning() && this.getDisableShopWarning().length > 0;
    },
    items() {
      return this.cart_items;
    },
    hasPickupOnlyItems() {
      var hasPickupOnlyItems = false;

      this.items.forEach(item => {
        if (item.pickupOnly == true) {
          hasPickupOnlyItems = true;
        }
      })

      return hasPickupOnlyItems;
    },
    hasDeliveryItems() {
      var hasDeliveryItems = false;

      this.items.forEach(item => {
        if (!item.pickupOnly || item.pickupOnly == false || item.pickupOnly == undefined) {
          hasDeliveryItems = true;
        }
      })

      return hasDeliveryItems;
    },
    subtotalDeliveryItems() {
      var total = 0;
      this.items.forEach(item => {
        if (!item.pickupOnly || item.pickupOnly == false || item.pickupOnly == undefined) {
          total += item.quantity * item.price;
        }
      });
      return total;
    },
    shipping() {
      if ((this.hasPickupOnlyItems && !this.hasDeliveryItems) || this.deliveryType == "pickup") {
        return 0;
      }
      return this.subtotalDeliveryItems >= 49 ? 0 : 5;
    }
  },
  methods: {
    ...mapGetters(["getDisableShopWarning"]),
    updateDeliveryType(type) {
      this.deliveryType = type;
    },
    tryConfirm() {
      if (this.$refs.addressForm.validate()) {
        this.checkout();
      }
    },
    checkout() {
      this.$refs.btnCheckout.load();

      var stripe = window.Stripe('pk_live_51HrLJkJdkbSHBNW1qhlvlmTgtIwWmQCs70zWegmwodYhEGUOIXCHaalx5VSFoA5ppPJS4b1IghnwJUQwBuYnp2UA00LqEfTvG4');

      var cart_items = [...this.items];

      if (this.hasPickupOnlyItems && !this.hasDeliveryItems) {
        this.deliveryType = "pickup";
      }

      const initRequest = {
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: JSON.stringify({
            cart: JSON.stringify(cart_items),
            form: JSON.stringify(this.$refs.addressForm.form),
            deliveryType: this.deliveryType
          })
        })
      }
    
      return fetch('https://europe-west1-webshop8-3bc62.cloudfunctions.net/createCheckoutSession', initRequest)
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((result) => {
        this.resetCart();
        return stripe.redirectToCheckout({ sessionId: result.result.id });
      })
      .then((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          this.$refs.btnCheckout.complete("FAILED");
          alert(result.error.message);
        }
      })
      .catch((error) => {
        this.$refs.btnCheckout.complete("FAILED");
        console.error('Error:', error);
      });
    }
  },
  created() {
    if (this.getDisableShopWarning() && this.getDisableShopWarning().length > 0) {
      //this.$router.push("/");
    }
  },
  components: {
    Address,
    Delivery
  }
}
</script>

<style lang="scss" scoped>
  .checkout {

  }
</style>