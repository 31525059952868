import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: require('./views/Home.vue').default
    },
    {
      path: '/about',
      name: 'About',
      component: require('./views/About.vue').default
    },
    {
      path: '/shop',
      name: 'Shop',
      component: require('./views/Shop.vue').default
    },
    {
      path: '/shop/:category',
      name: 'Shop',
      props: (route) => ({ category: route.params.category }),
      component: require('./views/Shop.vue').default
    },
    {
      path: '/item/:itemId',
      name: 'ShopItem',
      props: (route) => ({ itemId: route.params.itemId }),
      component: require('./views/ShopItem.vue').default
    },
    {
      path: '/cart',
      name: 'Cart',
      component: require('./views/Cart.vue').default
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: require('./views/Checkout.vue').default
    },
    {
      path: '/contact',
      name: 'Contact',
      component: require('./views/Contact.vue').default
    },
    {
      path: '/complete',
      name: 'Complete',
      component: require('./views/Complete.vue').default
    },
    {
      path: '/cancelled',
      name: 'Cancelled',
      component: require('./views/Cancelled.vue').default
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: require('./views/Privacy.vue').default
    },
    {
      path: '/sale-conditions',
      name: 'SaleConditions',
      component: require('./views/SaleConditions.vue').default
    },
    /*{
      path: '/404',
      name: '404*',
      component: require('./views/404.vue').default
    }*/
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 300);
    });
  }
});

router.beforeEach((to, from, next) => {
  next();
});


Vue.use(
  VueGtag,
  {
    config: { id: "UA-123456789-1" },
    params: {
      send_page_view: false
    }
  },
  router
);

export default router;