<template>
  <div class="home">
    <Header />
    <NewlyAdded />
    <ContactBlock />
  </div>
</template>

<script>
import Header from '../components/home/Header';
import NewlyAdded from '../components/home/NewlyAdded';
import ContactBlock from '../components/global/ContactBlock';

export default {
  name: 'Home',
  components: {
    Header,
    NewlyAdded,
    ContactBlock
  }
}
</script>

<style lang="scss" scoped>
  .home {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-top: 0 !important;
  }
</style>