<template>
  <div class="shop-item">
    <!--<Gallery :itemId="itemId" />-->
    <ItemImage :itemId="itemId" />
    <DetailView :product="product" />

    <ContactBlock />
  </div>
</template>

<script>
import firebase from 'firebase';

//import Gallery from '@/components/shop-item/Gallery';
import ItemImage from '@/components/shop-item/ItemImage';
import DetailView from '@/components/shop-item/DetailView';
import ContactBlock from '@/components/global/ContactBlock';

export default {
  name: 'ShopItem',
  props: ["itemId"],
  data() {
    return {
      product: {}
    }
  },
  methods: {
    getItem() {
      firebase.firestore().collection("products").doc(this.itemId)
        .get()
        .then((doc) => {
          this.product = {...doc.data(), id: doc.id};
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  },
  created() {
    this.getItem();
  },
  components: {
    //Gallery,
    ItemImage,
    DetailView,
    ContactBlock
  }
}
</script>

<style lang="scss">
  .shop-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 100px;


    .item-image {
      width: 100%;
      padding: 100% 0 0 0;

      @include tablet {
        width: 50%;
        padding: 50% 0 0 0;
      }
    }

    .detail-view {
      width: 100%;

      @include tablet {
        width: 50%;
      }
    }

    .contact-block .form {
      width: 100%;
    }
  }
</style>