<template>
  <div class="complete">
    <h1>Thank you!</h1>
    <p>
      We have succesfully received your order. A confirmation mail will be sent to you shortly.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Complete'
}
</script>

<style lang="scss" scoped>
  .complete {
    text-align: center;
    padding-top: 100px;
  }
</style>