<template>
  <div class="totals-and-delivery">
    <div class="row">
      <span>Subtotal</span>
      <span>{{ formatPrice(subtotal) }}</span>
    </div>

    <div class="row">
      <span>Shipping</span>
      <span v-if="hasDeliveryItems">{{ shipping == 0 ? "FREE" : formatPrice(shipping) }}</span>
      <span v-else>Pick-up only</span>
    </div>

    <div class="line" />

    <div class="row bold">
      <span>Total</span>
      <span>{{ formatPrice(total) }}</span>
    </div>

    <BigButton ref="btnCheckout" @click.native="goToCheckout" :disableButton="shopDisabled" v-bind:class="{ disabled: shopDisabled }">CHECKOUT</BigButton>

    <p style="margin: 20px 0; color: orange;" v-if="shopDisabled">{{ getDisableShopWarning() }}</p>

    <p v-if="hasPickupOnlyItems" style="margin: 20px 0; color: orange;">
      <span v-if="hasDeliveryItems">1 or more items are pick-up only.</span>
      <span v-else>Your order can only be picked-up. No delivery.</span>
    </p>

    <div class="delivery">
      <div class="big-text-row">
        <img src="@/assets/images/feather/package.svg">
        <span>FREE DELIVERY</span>
      </div>
      <p class="small-text-row">Free shipping for orders above € 49, 00</p>
    </div>

    <div class="delivery">
      <div class="big-text-row">
        <img src="@/assets/images/feather/shield.svg">
        <span>PRIVACY GUARANTEED</span>
      </div>
      <p class="small-text-row">Read our <router-link to="/privacy">Privacy Policy</router-link></p>
    </div>

    <div class="delivery">
      <div class="big-text-row">
        <img src="@/assets/images/feather/tag.svg">
        <span>SALE CONDITIONS</span>
      </div>
      <p class="small-text-row">Read our <router-link to="/sale-conditions">Store Policy</router-link></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "TotalsAndDelivery",
  props: ["items"],
  computed: {
    shopDisabled() {
      return this.getDisableShopWarning() && this.getDisableShopWarning().length > 0;
    },
    hasPickupOnlyItems() {
      var hasPickupOnlyItems = false;

      this.$props.items.forEach(item => {
        if (item.pickupOnly == true) {
          hasPickupOnlyItems = true;
        }
      })

      return hasPickupOnlyItems;
    },
    hasDeliveryItems() {
      var hasDeliveryItems = false;

      this.$props.items.forEach(item => {
        if (!item.pickupOnly || item.pickupOnly == false || item.pickupOnly == undefined) {
          hasDeliveryItems = true;
        }
      })

      return hasDeliveryItems;
    },
    subtotal() {
      var total = 0;
      this.items.forEach(item => {
        total += item.quantity * item.price;
      });
      return total;
    },
    subtotalDeliveryItems() {
      var total = 0;
      this.items.forEach(item => {
        if (!item.pickupOnly || item.pickupOnly == false || item.pickupOnly == undefined) {
            total += item.quantity * item.price;
        }
      });
      return total;
    },
    shipping() {
      if (this.hasPickupOnlyItems && !this.hasDeliveryItems) {
        return 0;
      }
      return this.subtotalDeliveryItems >= 49 ? 0 : 5;
    },
    total() {
      return this.subtotal + this.shipping;
    }
  },
  methods: {
    ...mapGetters(["getDisableShopWarning"]),
    formatPrice(price) {
      return isNaN(price) ? "" : "€ " + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', ', ');
    },
    goToCheckout() {
      this.$router.push({ path: "/checkout" });
    }
  }
}
</script>

<style lang="scss" scoped>
  .totals-and-delivery {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    margin: 10px 0;

    span {
      &:first-child {
        margin-right: auto;
      }
    }

    &.bold {
      span {
        font-family: 'Sahar-Bold';
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 10px 0;
  }

  .delivery {
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px 0;

    .big-text-row {
      display: flex;
      align-items: flex-end;

      img {
        margin-right: 10px;
        height: 25px;
      }

      span {
        font-family: 'Sahar-Bold';
        font-size: 15px;
      }
    }

    .small-text-row {
      font-size: 11px;
      margin-top: 5px;

      a {
        color: black;
        text-decoration: underline;
      }
    }
  }
</style>