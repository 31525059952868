<template>
  <div class="cookiebar">
    <img src="@/assets/images/cookie.png" class="rotatingCookie">
    <p>We use cookies.</p>
    <div class="btnOK" @click="acceptCookies">OK!</div>
  </div>
</template>

<script>
export default {
  name: 'Cookiebar',
  methods: {
    acceptCookies() {
      this.$parent.acceptCookies();
      this.$cookie.set('acceptsCookies', "true", { expires: '1Y' });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  
  .cookiebar {
    background: black;
    color: white;
    position: fixed;
    bottom: -80px;
    z-index: 999;
    right: 30px;
    height: 80px;
    padding: 22px 22px 22px 20px;
    transition: all .7s ease-in-out;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.5);

    &.active {
      bottom: 30px;
    }
  }

  .rotatingCookie {
    border: none;
    height: 35px;
    float: left;
    animation: cookie-rotate 2s ease-in-out;
    animation-iteration-count: infinite;
  }

  p {
    padding: 0px 10px;
    height: 36px;
    float: left;
    line-height: 36px;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .btnOK {
    height: 30px;
    margin-top: 3px;
    padding: 0 9px;
    text-decoration: none;
    float: left;
    text-align: center;
    background: white;
    color: black;
    font-weight: 700;
    font-size: 13px;
    line-height: 32px;
    transition: all .25s ease-in-out;
    border: 1px solid white;

    &:hover {
      cursor: pointer;
      background: black;
      color: white;
    }
  }

  @keyframes cookie-rotate {
    0% {transform: rotate(0deg);}
    75% {transform: rotate(360deg);}
    100% {transform: rotate(360deg);}
  }
</style>