<template>
  <div class="shop-filter">
    <BigSelect :items="categories" v-model="category" @change="categoryChanged" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ShopFilter",
  props: ["category"],
  data() {
    return {
      categories: [{value: "ALL", text: "ALL"}]
    }
  },
  computed: {
    categoryList() {
      return this.getCategories();
    }
  },
  watch: {
    categoryList() {
      this.fillCategories();
    }
  },
  methods: {
    ...mapGetters(["getCategories"]),
    categoryChanged() {
      if (this.category != "ALL") {
        this.$router.push({ path: '/shop/' + this.category.toLowerCase() });
      } else {
        this.$router.push({ path: '/shop' });
      }
    },
    fillCategories() {
      this.getCategories().forEach(category => {
        this.categories.push({
          value: category,
          text: category
        })
      })

      // wis category en add hem daarna weer om select te refreshen
      if (this.$props.category) {
        var cat = this.$props.category;
        this.$props.category = "";
        this.$nextTick(() => {
          this.$props.category = cat.toUpperCase();
        })
      }
    }
  },
  created() {
    this.fillCategories();
  }
}
</script>

<style lang="scss" scoped>
  .shop-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>