<template>
  <div class="delivery">
    <h2>Delivery</h2>
    
    <div v-if="hasPickupOnlyItems && hasDeliveryItems">
        1 or more items are pick-up only.<br>
        Pick-up every item or deliver some with post?
    </div>

    <div v-else-if="hasPickupOnlyItems && !hasDeliveryItems">
        Note! You have to pick-up all the items. None are deliverable.
    </div>

    <div v-else-if="pickupPossible">
        Do you want to pick-up your order or should we deliver it?
    </div>

    <div v-else>
        We will deliver your order as soon as possible after we receive the payment.
    </div>
    
    <div class="delivery-radios" v-if="showDeliveryOptions">
        <div class="radio-row">
            <input type="radio" name="deliveryType" id="pickup" value="pickup" v-model="deliveryType">
            <label for="pickup">Pick-up at our store</label>
        </div>
        <div class="radio-row">
            <input type="radio" name="deliveryType" id="delivery" value="deliver" v-model="deliveryType">
            <label for="delivery">Deliver to your address</label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Delivery",
  props: ["hasPickupOnlyItems", "hasDeliveryItems"],
  data() {
    return {
      pickupPossible: true,
      deliveryType: "deliver"
    }
  },
  watch: {
    deliveryType(val) {
      this.$emit("deliveryTypeChanged", val);
    }
  },
  computed: {
    showDeliveryOptions() {
      return (this.hasPickupOnlyItems && this.hasDeliveryItems) || (!this.hasPickupOnlyItems && this.pickupPossible);
    }
  }
}
</script>

<style lang="scss" scoped>
  .delivery {
    margin: 40px 0;
  }

  .delivery-radios {
    margin: 20px 0;

    .radio-row {
        margin: 5px 0;

        label {
            margin-left: 10px;
            cursor: pointer;
        }
    }
  }
</style>