<template>
  <div class="header">
    <router-link to="/shop" class="btnShop">SHOP</router-link>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style lang="scss" scoped>
  .header {
    width: 100%;
    height: calc(70vh - 80px);
    max-height: 1000px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: url('../../assets/images/home-header-placeholder-bg.jpg');
    background: url('https://firebasestorage.googleapis.com/v0/b/webshop8-3bc62.appspot.com/o/assets%2Fhome-header.jpg?alt=media');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;

    @include desktop {
      background-attachment: fixed;
    }
  }

  .btnShop {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background-color: black;
    color: white;
    padding: 12px 50px;
    
    font-family: 'Sahar-Bold', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;

    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: #212121;
    }
  }
</style>