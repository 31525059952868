<template>
  <select class="big-select" :value="value" @input="input" @change="changed">
    <option v-for="item in items" :key="item.value" :value="item.value">{{ item.text }}</option>
  </select>
</template>

<script>
export default {
  name: "BigSelect",
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    input($event) {
      this.$emit('input', $event.target.value);
    },
    changed($event) {
      this.$emit('change', $event.target.value);
    }
  }
}
</script>

<style lang="scss" scoped> 
  .big-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    margin: 15px 0;
    padding: 0 20px 0 10px;
    width: 100%;
    height: 35px;
    max-width: 300px;

    border: 2px solid black;
    border-radius: 0;

    background-color: white;
    background-image: url('../../assets/images/select-arrow.png');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position-x: calc(100% - 10px);
    background-position-y: 12px;

    font-family: "Sahar-Bold";
    font-size: 16px;
    line-height: 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      background-color: #FCFCFC;
    }
  }
</style>