<template>
  <div class="newly-added" v-if="items.length > 0">
    <h2>Newly Added</h2>

    <div class="item-list">
      <Item v-for="item in items" :key="item" :item="item" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import Item from '../shop/Item';

export default {
  name: "NewlyAdded",
  data() {
    return {
      items: []
    }
  },
  methods: {
    getNewestProducts() {
      var query = firebase.firestore().collection("products");
      query = query = query.where("hidden", "==", false);

      query.orderBy("created_at", "desc").limit(3).get()
        .then((querySnapshot) => {
          this.items = [];
          
          querySnapshot.forEach((doc) => {
            var product = doc.data();
            product.id = doc.id
            this.items.push(product);
          });

          this.lastRetrievedItem = querySnapshot.docs[querySnapshot.docs.length - 1];

          if (querySnapshot.docs.length < 12) {
            this.loadedEverything = true;
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  },
  created() {
    this.getNewestProducts()
  },
  components: {
    Item
  }
}
</script>

<style lang="scss" scoped>
  .newly-added {
    padding: 100px 0;

    h2 {
      text-align: center;
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    margin: auto;
    
    .item, .empty-space {
      width: 100%;
      margin: 20px auto;

      @include tablet {
        width: calc(33% - 20px);
        margin: auto;
      }
    }
  }
</style>